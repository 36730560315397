import React, { useState } from "react"
import view from "./addtlButtons.module.scss"
import { Link } from "gatsby";
import useModal from "../../hooks/useModal"
import AvailabilityForm from "../modal/content/AvailabilityForm"

function AddtlButtons(props) {
  const override = props.data.overrideTheme;
  const buttons = props.data.buttons;
  const bgColor = props.data.bgColor;
  const document = typeof window !== 'undefined' && window.document

  const [renderModal] = useModal({
    title: "",
    location: "#contact"
  })

  const BackgroundStyle = override ? {background: bgColor} : {}
  const renderButtons = () => (
    buttons.map(btn => {
      const BtnTextColorStyle = override ? {color: btn.btnTxtColor} : {}
      const BtnBackgroundColorStyle = override ? {background: btn.btnColor} : {}
      if (btn.btnImage != '')
        return(
          <span className={view["action-link"]} style={BackgroundStyle}>
            <Link className={view["action-link"]} to={btn.btnLink} alt={btn.btnText} style={BackgroundStyle}>
              <img className={view["insta-img"]} src={btn.btnImage} />
            </Link>
          </span>
        )
      else {
        return (
          <Link  to={btn.btnLink} className={view["action-btn"]} style={BtnBackgroundColorStyle} alt={btn.btnText}>
            <span style={BtnTextColorStyle}>{btn.btnText}</span>
          </Link>
        )
      }
    })  
  )

  const modalContactContent = (
    <AvailabilityForm leadURL={props.leadURL}
                      pageSource='Contact' pageURL={document.URL} site_id={props.site_id}
                      extraData={ props.site_id }
                      disclaimerContact={props.disclaimerContact} />
  )

  return (
    <>
      {renderModal(modalContactContent)}
      {buttons.length > 0 &&
        <section className={view["special-btns"]} style={BackgroundStyle}>
          {renderButtons(buttons)}
        </section>
      }
    </>
  )
}

export default AddtlButtons