import React, { useEffect, useState } from "react";
import view from "./directions.module.scss";
import { GoogleApiWrapper } from 'google-maps-react';
import Geocode from "react-geocode";
import Style from './findDealerMapStyles';

const googleAPIkey = 'AIzaSyCddq6ehnmTvcAdFOvG5xEGhn8jZV3eBqY';

const FindDealerMap = (props) => {

  const [mapInteraction, setMapInteraction] = useState('');
  const mapProps = props.props;
  const google = props.google;
  const style = Style;
  const [coordinates, setCoordinates] = useState({ lat: null, lng: null });
  const markers = mapProps.dealerLocals;
  const mapMarkerLink = view["map-marker"];

  Geocode.setApiKey(googleAPIkey);

  useEffect(() => {
    if (props.props.data.dealerAddress) {
      Geocode.fromAddress(props.props.data.dealerAddress)
        .then(response => {
          const { lat, lng } = response.results[0].geometry.location;
          setCoordinates({ lat, lng });
          initMap(lat, lng); 
        })
        .catch(err => {
          console.error("Geocoding error:", err);
        });
    }
  }, [props.props.data.dealerAddress]);

  useEffect(() => {
    let mobileScreenSize = (window.innerWidth <= 760);
    setMapInteraction(mobileScreenSize ? 'click' : 'mouseover');
  }, []);

  const initMap = (lat, lng) => {
    const node = document.getElementById("map");
    const mapConfig = {
      maxZoom: 15,
      center: { lat: parseFloat(lat), lng: parseFloat(lng) },
      containerStyle: { height: '100%' },
      style: { height: '100%', width: '100%' },
      mapTypeControl: false,
      streetViewControl: false,
      zoomControl: false,
      fullScreenControl: false,
      google: google,
      styles: style
    };
  
    const map = new google.maps.Map(node, mapConfig);
    let infowindow = new google.maps.InfoWindow();
    let bounds = new google.maps.LatLngBounds();
  
    const dealerMarker = new google.maps.Marker({
      position: new google.maps.LatLng(lat, lng),
      map: map,
      icon: {
        url: 'https://media.dealermasters.com/AstonMartin/demo/MapPin.webp', 
        scaledSize: new google.maps.Size(26.5, 36) 
      }
    });
  
    bounds.extend(dealerMarker.getPosition());
    
    map.fitBounds(bounds);
  };  

  return <div id="map" className={view["map"]}></div>;
};

export default GoogleApiWrapper({
  apiKey: googleAPIkey
})(FindDealerMap);
